@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://use.fontawesome.com/releases/v5.0.1/css/all.css');
:root{
    --basefontfamily: 'Inter', sans-serif;
    --basecolor: #3794fc;
    --basefontsize: 1em;
    --basefontcolor:#2c2c34;
    --baselineheight:1.4em;
    --headertopbackgroundcolor:#f5f5f5;
    --Poppinsfont:'Poppins', sans-serif;
    --fontsize11:0.688em;
    --fontsize13:0.813em;
    --fontsize14:0.875em;
    --fontsize15:0.938em;
    --fontsize16:1em;
    --fontsize17:1.063em;
    --fontsize18:1.125em;
    --fontsize19:1.188em;
    --fontsize20:1.25em;
    --fontsize22:1.375em;
    --fontsize24:1.5em;
    --fontsize27:1.688em;
    --fontsize28:1.75em;
    --fontsize30:1.875em;
    --fontsize32:2em;
    --fontsize38:2.375em;
    --fontsize58:3.625em; 
    --fontsize59:3.688em;
    --greencolor:#77c584;
    --colorwhite:#fff;
    --h1fontsize:2.5em;
    --h1lineheight:1.3;
    --featuredbg:#f3f3f3;
    --copyrightcolor:#949494;

}

@media only screen and (min-width: 1400px) {
  :root{
    --h1fontsize:3.375em;
  }
}

body {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}






*{margin:0px; padding:0px; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; font-size: 100%; }
html{ width:100%; height:100%; margin:0px; padding:0px; overflow-x: hidden; }
body{ margin:0px; padding:0px; background:#fff; font-size: var(--basefontsize); line-height: var(--baselineheight); color: var(--basefontcolor); font-family: var(--basefontfamily); overflow-x: hidden;  }
p{ font-size: var(--basefontsize); line-height: var(--baselineheight); color: var(--basefontcolor);  font-weight: 400; margin: 0 0 25px 0; }
p:last-child{ padding: 0; margin: 0;}
h1{ margin: 0px; padding: 0 0 30px 0;  font-weight: 700; font-size: var(--h1fontsize); line-height: var(--h1lineheight); }
h2{ margin: 0px; padding: 0px; font-weight: 700; font-size: var(--fontsize38); color: var(--basefontcolor);  padding-bottom: 20px;   }
h3{ margin: 0px; padding: 0px;   }
h4{ margin: 0px; padding: 0px; font-weight: 600; font-size: var(--fontsize20); color: var(--basefontcolor);  padding-bottom: 20px; }
h5{ margin: 0px; padding: 0px;  }
h6{ margin: 0px; padding: 0px;  }


address, blockquote, dd, div, dl, dt, fieldset, form, frame, frameset{ margin:0; padding:0; border: 0px; outline: none; }
label, legend, table, caption, tbody, tfoot, thead, tr, th, td { margin:0px; padding:0; outline:none; border:0;}
a{ text-decoration:none; border: 0px; outline: none; transition: all .5s;}
a:hover, a:focus, a:active{ text-decoration:none; border: 0px; outline: none; transition: all .5s;}
a img{ border:none; outline: none;}
a:focus {outline:none; } 
border{ border-collapse:collapse;}
hr{ height:1px; background:#b1b1b1; border:none; margin: 0;}
.clear{ clear:both;}
.left{ float:left;}
.right{ float:right;}
.clearfix:before,
.clearfix:after { content: " "; display: table;}
.clearfix:after { clear: both;}
.clearfix { *zoom: 1; clear: both;}
img { max-width: 100%; height: auto;}
::-moz-selection { color: #fff; background: #67919f;}
::selection { color: #fff; background: #67919f;}

.headerTop{ background-color: var(--headertopbackgroundcolor); padding: 5px 0;}
.headerTopContent{ display: flex; justify-content: flex-end;}
.headerTopContent ul{ margin: 0; padding: 0; list-style: none; display: flex; align-items: center;}
.headerTopContent ul li{ display:flex; align-items: center; margin: 0 15px; position: relative; line-height: 22px; font-size: var(--fontsize14); color: var(--basefontcolor); font-weight: 500; font-family: var(--Poppinsfont); font-weight: 500; position: relative;}
.headerTopContent ul li:last-child{ margin: 0 0 0 15px;}
.headerTopContent ul li:first-child::after{ content: ''; position: absolute; right: -15px; top: 0; width: 1px; height: 20px; background-color: #aaaaaa; }
.headerTopContent ul li a{ text-decoration: underline; color: var(--basecolor); }
.headerTopContent ul li:first-child a{ color: var(--basefontcolor); text-decoration: none;}
.headerTopContent ul li:first-child svg{ color: var(--basecolor); font-size: var(--fontsize22);  margin: 0 5px 0 0; }
.headerTopContent ul li a:hover{ color: var(--greencolor);}
.headerTopContent ul li:first-child a:hover{ color: var(--basecolor);}
.smaillimage{ margin: 0 0 0 5px;}
.marginLeft{ margin-left: 35px;}
.navHolder .navbar {  padding: 20px 0;}
.navHolder .nav-link{ padding: 0 25px!important;  font-weight: 500; font-size: var(--basefontsize); color: var(--basefontcolor);}
.navHolder .nav-link:hover{ color: var(--basecolor);}
.navHolder a.nav-link.active{ color: var(--basecolor);}

.headerLogin a{margin: 0 25px;  font-weight: 500; font-size: var(--basefontsize); color: var(--basefontcolor); }
.headerLogin a:hover{ color: var(--basecolor);}
.headergetStarted a{  font-weight: 500; font-size: var(--basefontsize); color: var(--colorwhite); background-color: var(--greencolor); display: block; padding: 15px 25px; border-radius: 7px; }
.headergetStarted a:hover{ background-color: var(--basecolor);}
.bannerSection{ padding: 10px 0 0;}
.bannerSection p{ font-size: var(--fontsize17); font-weight: 500; position: relative ; z-index: 1;  }
.bannerSection p strong{ color: var(--basecolor); font-weight: 700;}
.bannerSection p br{ display: none;}




@media only screen and (min-width: 1400px) {
  .bannerSection p br{ display: block;}
}

.bannerImage{  position: relative; margin-left: -100px; margin-right: -100px;}
a.btnGetStarted{  font-weight: 500; font-size: var(--basefontsize); color: var(--colorwhite); background-color: var(--basecolor); display: inline-block; padding: 18px 25px; border-radius: 7px; margin-top: 25px;}
a.btnGetStarted:hover{ background-color: var(--greencolor);}
.bannerSection h1 span{ background: url(../images/h1Decoration.png) no-repeat;  background-position: bottom; padding: 0 0 10px 5px; background-size: 110px;}

.featuredLeft{ background: var(--featuredbg); border-radius: 0 120px 0 0; position: relative;}
.featuredLeft:before{ content: ''; position: absolute; width: 999em; background-color: var(--featuredbg); border-radius: 0 120px 0 0; top: 0; right: 0; height: 100%;}

.featuredRight{  border-radius: 120px 0 0 0; position: relative; background: rgb(63,146,248); background: linear-gradient(151deg, rgba(63,146,248,1) 0%, rgba(134,126,209,1) 100%);}
.featuredRight:after{ content: ''; position: absolute; width: 999em; border-radius: 120px 0 0 0; top: 0; left: 0; height: 100%; background: linear-gradient(151deg, rgba(63,146,248,1) 0%, rgba(134,126,209,1) 8%);}
.featuredPadding{ padding: 70px; position: relative; }
.featuredHolder{ position: relative; z-index: 9;}
.featuredLeftPadding{ padding: 85px 70px 70px 0;}
.featuredRightPadding{ padding: 85px 0 70px 70px;}

.featuredHolder h5{  font-weight: 500; color: var(--basefontcolor); text-transform: uppercase; font-size: var(--fontsize13); padding: 0 0 12px 0; }
.featuredHolder h2{ font-size: var(--fontsize59); font-weight: 700; padding: 0 0 35px 0;}
.featuredHolder h2 span{ font-size: 28px; font-weight: 500; }

.featuredHolder ul{ margin: 0; padding: 0; list-style: none;}
.featuredHolder ul li{ float: none; display: block; position: relative; font-weight: 500; line-height: 20px; padding: 0 0 0 30px; margin: 0 0 18px 0; font-size: var(--fontsize15); }
.featuredHolder ul li:after{ content: '\f14a'; font-family: "Font Awesome 5 Free";   font-weight: 900; position: absolute; left: 0; color: #3794fc; font-size: 22px; top: 0; }
.pointsHolder{ display: flex; }
.pointleft{ width: 85%;}
.featuredRight *{ color: var(--colorwhite);}
.featuredRight h5{color: var(--colorwhite); }
.featuredRight .featuredHolder ul li:after{ color: var(--colorwhite);}
.featuredMidImage{ position: absolute; width: 267px; right: 12px; bottom: 90px; z-index: 1;}

.navbar{ transition: all .5s;}
.fixed-header { position: fixed; top: 0; left: 0; width: 100%; z-index: 999; background-color: #fff; box-shadow: 0 2px 6px rgba(0,0,0,0.10); animation: slideDown 0.35s ease-out;}
.navbar.fixed-header{ padding: 5px 0; transition: all .5s;}

@keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

.emplyeeSection{ background: url(../images/sectionBg.jpg) no-repeat bottom center #fff; background-size: cover; padding: 100px 0 0 0; position: relative; }
/* .emplyeeSection:after{ content: ''; position: absolute; bottom: 0; background: url(../images/partitionBg.png) no-repeat bottom center; height: 200px; width: 100%; left: 0; background-size: cover; } */



.emplyeeSectioninner{ display: block; position: relative;}
.emplyeeSectioninner p{ font-weight: 500; line-height: 1.6;}
.emplyeeSectioninner p span{ font-weight: 800; color: var(--basecolor);}
.paddingContainer{ padding: 0 75px 50px;}


.emplyeeSection ul{ margin: 0; padding: 0 25px; list-style: none;}
.emplyeeSection ul li{ float: none; display: block; position: relative; font-weight: 500; line-height: 20px; padding: 0 0 0 30px; margin: 0 0 18px 0; font-size: var(--basefontsize); line-height: 1.5; }
.emplyeeSection ul li:after{ content: '\f14a'; font-family: "Font Awesome 5 Free";   font-weight: 900; position: absolute; left: 0; color: #3794fc; font-size: 22px; top: 0; }
.emplyeeBelowImage{ margin-top: 50px; position: relative;}
.emplyeePartionImage{ position: absolute; width: 100%; height: auto; bottom: 0; left: 50%; transform: translateX(-50%); z-index: 1;}
.emplyeePartionImage img{ width: 100%;}
.sideMobileImage{ position: absolute; right: -45px; bottom: -25px; z-index: 9; }

.teamworkSection{ padding: 90px 0; background: #fff;}
.teamworkSectionInner{ display: block; position: relative; }
.sliderImage{ padding: 15px;}
.sliderImage img{ border: 2px solid #e9e9e9; border-radius: 30px; box-shadow: 0 4px 13px rgba(55,148,252,0.24); }
.sliderNavHolder .slick-slide{ width: 50% !important;}
.icon{ margin: 0 0 20px 0;}
.icon_card{ margin: 0 0 40px 0; cursor: pointer;}
.sliderNavHolder h2{ margin-bottom: 25px;}
.sliderNavHolder .slick-track{ display: flex; flex-wrap: wrap; }
.cardleft{ padding: 0 20px 0 0;}
.cardright{ padding: 0 0 0 20px;}
.icon img{filter: invert(1) brightness(0); transition: all .5s;}
.sliderNavHolder .slick-current .icon img{ filter: invert(0) brightness(1); transition: all .5s; }

.managementSection{ overflow: hidden;}

.managementSection_inner{padding: 65px 0 105px 0; background: rgb(255,255,255); background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(240,244,255,1) 100%);   border-bottom-left-radius: 50% 25%; border-bottom-right-radius: 50% 25%; width: 110%; margin-left: -5%; }

.iconBoxHolder{ display: flex; flex-wrap: wrap;}
.managementSection .icon_card{ width: 50%;}

.managementSection .cardleft{ padding: 0 50px 0 0;}
.managementSection .cardright{ padding: 0 0 0 50px;}

.managementSection .icon_card:hover img{ filter: invert(0) brightness(1); transition: all .5s; }
.managementSection_inner{ position: relative;}
.managemtImage{ position: absolute; left: 55%; top: 50px; width: 45%; text-align: left;}
.managementSection h2{ margin-bottom: 45px;}
.customerSection{ padding: 110px 0;}
.customerBoxHolder{ display: flex; justify-content: center; margin: 40px 0 0 0;}
.customerBox{ width: 445px; margin: 0 20px; position: relative ; border-radius: 10px; border: 1px solid #e9e9e9; box-shadow: 0 4px 13px rgba(63,146,248,0.24); padding-bottom: ;  }
.customerTop{ padding: 40px 35px 100px 35px; position: relative;}
.quoteIcon{ position: absolute; top: -22px; left: 35px; z-index: 1;}
.starRating ul{ margin: 0 0 15px 0; padding: 0; list-style: none;}
.starRating ul li{ float: none; display: inline-block; color: #ffc106; margin: 0 3px 0 0;}
.customerTop h3{ font-weight: 600; font-size: var(--fontsize24); padding: 0 0 15px 0; }
.customerTop p{ font-size: var(--fontsize15); line-height: 1.5; font-weight: 400;}
.customerBottom{ background: rgb(56,148,251); background: linear-gradient(90deg, rgba(56,148,251,1) 0%, rgba(198,134,188,1) 100%); padding: 12px 40px; border-radius: 0 0 10px 10px; position: absolute; bottom: 0; left: 0; width: 100%;}
.customerName{ font-weight: 600; color: #fff; font-size: var(--fontsize17); }
.customerName span{ display: block; font-size: var(--fontsize13); font-weight: 300;}

.informationSection{ padding: 85px 0 75px 0; background: #f3f3f3;}
.informationHolder{ display: block; margin:35px auto 0;}
.informationSection h2{ margin-bottom: 35px;}
.infoIconbox{ padding: 50px; background: #fff; border-radius: 20px; height: 100%;  transition: all .5s; position: relative;}
.infoIcon img{ height: 44px; width: auto; max-width: inherit;}
.infoIcon{ margin: 0 0 20px 0;}
.infoIconbox h3{ font-weight: 600; font-size: var(--fontsize19); padding: 0 0 12px 0;}
.infoIconbox p{ font-size: var(--fontsize15); line-height: 1.6;}
.infoIconbox:hover{ box-shadow: 0 14px 13px rgba(63,146,248,0.33); transition: all .5s;}
.comingSoonBadge{ position: absolute; top: 14px; right: 14px; background: #d4f0f6; padding: 7px; border-radius: 5px; font-size: var(--fontsize11); color: var(--basecolor); font-weight: 500; line-height: 1;} 
.runSection{ padding: 100px 0;}
.runHolder{ display: block;}
.runImage img{border: 2px solid #e9e9e9; border-radius: 30px; box-shadow: 0 4px 13px rgb(55 148 252 / 24%);}
.runSection .accordion-item h2{ margin: 0; padding: 0; font-weight: 600; font-size: var(--fontsize17); }
.runSection .accordion-item h2 button{ margin: 0; padding:15px 0; font-weight: 600; font-size: 17px; line-height: 24px; outline: none !important; box-shadow: none !important; border-bottom: 1px solid #e0eae9; background: transparent !important;}
.runSection .accordion-item h2 button span{ width: 75%;}
.runSection .accordion-body{ padding-left: 0; border-bottom: 5px solid #bbdafd;}
.runSection .accordion-body p{ font-size: var(--fontsize15); line-height: 1.6;}
.runSection .accordion-item{ border: none;}
.runSection .accordion-button:not(.collapsed){ color: var(--basecolor);}
.runSection .accordion-item:last-child button{ border: none; }
.runSection .accordion-button::after{ transform: rotate(-90deg); opacity: 0.3; height: 15px; background-size: contain;}
.runSection .accordion-button:not(.collapsed)::after{  transform: rotate(0deg); opacity: 1.0;}

.imageInfoSection{ overflow: hidden; }
.imageInfoSection_inner{background: rgb(240,244,255);  background: linear-gradient(180deg, rgba(240,244,255,1) 0%, rgba(255,255,255,1) 100%) ; padding: 140px 0 0px 0; border-top-left-radius: 50% 25%;  border-top-right-radius: 50% 25%; width: 110%;  margin-left: -5%;}

.smallContainer{ max-width: 1026px; margin: 0 auto; padding: 0 15px;}


.imageBox h3{ font-weight: 600; font-size: var(--fontsize20); padding: 0 0 15px 0;}
.imageBox p{ font-size: var(--fontsize15); line-height: 1.5;}
.imageboxImage{ margin: 0 0 45px 0;}
.mb-7{ margin-bottom: 80px;}

.commingSoonSection{ padding: 100px 0; background: #fff; }
.commingImage{ max-width: 320px; margin: 0 auto;}
.commingImage2{ max-width: 385px; text-align: right; }
.commingSoonSection h3{ font-weight: 600; font-size: var(--fontsize20); padding: 0 0 20px 0;}

.commingSoonSection ul{ margin: 0; padding: 0 ; list-style: none;}
.commingSoonSection ul li{ float: none; display: block; position: relative; font-weight: 500; line-height: 20px; padding: 0 0 0 30px; margin: 0 0 18px 0; font-size: var(--basefontsize); line-height: 1.5; }
.commingSoonSection ul li:after{ content: '\f14a'; font-family: "Font Awesome 5 Free";   font-weight: 900; position: absolute; left: 0; color: #3794fc; font-size: 22px; top: 0; }
.customerDevelopmentSection{ display: block; margin: 45px 0 0 0;}
.customerInner{ background: #f3f3f3; padding: 40px 100px; border-radius: 25px;}
.customerInner ul{ margin: 0; padding: 0; list-style: none;}
.customerInner ul li{ float: none; display: block; position: relative; font-weight: 500; line-height: 20px; padding: 0 0 0 30px; margin: 0 0 18px 0; font-size: var(--basefontsize); line-height: 1.5; }
.customerInner ul li:after{ content: '\f14a'; font-family: "Font Awesome 5 Free";   font-weight: 900; position: absolute; left: 0; color: #3794fc; font-size: 22px; top: 0; }
.customerImage{ padding-right: 75px;}

.abtSection{ padding: 100px 0;}
.aboutContainer{ max-width: 860px; margin: 0 auto; padding: 0 15px;}
.abtSection p{ font-size: var(--fontsize17); line-height: 1.6; }
.aboutBottomImage{ max-width: 330px; margin: 35px auto 0;}

.footerTop{ background: linear-gradient(151deg, rgba(63,146,248,1) 0%, rgba(134,126,209,1) 100%); padding: 70px 0 0 0; overflow: hidden; }
.footerImage{ max-width: 315px;}
.footerContent h2{ font-weight: 500; color: #fff; font-size: var(--fontsize30); margin: 0; padding-bottom: 15px; line-height: 1.4;}
.footerContent h2 span{ font-weight: 700;}
.footerContent p{ color: #ffffff; font-size: var(--fontsize15); padding: 0; margin: 0 0 15px 0;}
.footerContent h3{ font-weight: 600; color: #fff; font-size: var(--fontsize20); padding-bottom: 10px;}
.social ul{ margin: 0; padding: 0; list-style: none; }
.social ul li{ display: inline-block; vertical-align: top; margin: 0 12px 0 0; }
.social ul li a{ display: block; color: #fff; font-size: var(--fontsize22);}
.appButonHolder{ margin: 0 0 30px 0;}
.appButonHolder ul{ margin: 0; padding: 0; list-style: none;}
.appButonHolder ul li{ margin: 0 15px 0 0; max-width: 194px; vertical-align: top; display: inline-block;}
.footerContent{ padding: 50px 0 0 65px;}
.footerBottom{ background: #1b1b1b; padding: 40px 0;}
.footerLogo{ width: 185px;}
.fooerContent{ display: flex; justify-content: space-between; align-items: center;}
.copyright{ color: var(--copyrightcolor); font-size: var(--fontsize15);}
.footerNav ul{ margin: 0; padding: 0; list-style: none;}
.footerNav ul li{ float: none; display: inline-block; vertical-align: top; margin: 0 25px;}
.footerNav ul li a{ display: block; color: var(--copyrightcolor); font-size: var(--fontsize15); font-weight: 500;}
.footerNav ul li a:hover{ color: var(--basecolor);}
.emailicon{ display: none;}

.pricPageBanner{ padding: 100px 0 370px; background: hsla(0, 0%, 100%, 1);  background: linear-gradient(180deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 95%, 1) 100%);}
.pricePagebannerContent{ margin: 55px 0 0 0; border-bottom-width: 8px;  border-style: solid;  border-image: linear-gradient(to right, #3794fc, #c988be) 1;}
.pricePagebannerContent img{ width: 100%;}
.pricePagebannerContent{ position: relative; margin-top: -330px;}
.priceTableHolder{ border: 1px solid rgba(206,206,206,0.44); box-shadow: 0 4px 13px rgba( 55,148,252,0.23);  background: #ffffff; border-radius: 0 0 17px 17px; margin:0; overflow: hidden;}
.priceTableHolder th, .priceTableHolder td{ padding: 20px 38px;}
.priceTableHolder table{ margin: 0;}
.priceTableHolder th, .priceTableHolder td{ border: 1px solid #f3f3f3;}
.priceTableHolder td{ font-size: var(--fontsize15); color: var(--basefontcolor);}
.priceTableHolder td:not(:first-child){ text-align: center; color: #3794fc !important; font-size: var(--fontsize28);}
.priceTableHolder th small{ font-size: var(--fontsize15); font-weight: 600; padding: 10px; background: var(--basecolor); border-radius: 6px; color: #fff; display: inline-block; margin-top: 7px;}
.priceTableHolder th:nth-child(2) span{ display: block; font-weight: 700; font-size: var(--fontsize58); color: var(--basefontcolor); line-height: 1.6;}
.priceTableHolder th:nth-child(2) { text-align: center;}

.tablelist{ display: flex; align-items: center; background: #fff; box-shadow: 0 4px 13px rgba( 55,148,252,0.23); border: 1px solid rgba(206,206,206,0.44); border-radius: 5px; padding: 12px 15px; margin: 0 0 15px 0; }
.tableListitems{ padding-top: 17px; margin-bottom: 20px;}
.listIcon{ width: 25px; flex-shrink: 0;}
.listContent{ padding-left: 25px; font-size: var(--fontsize14) ; font-weight: 500; line-height: 1; }
.listContent span{ font-weight: 700; font-size: var(--fontsize32);}
.priceTableHolder th:first-child{ font-weight: 600; font-size: var(--fontsize27); padding-bottom: 40px; }

.priceTableHolder th:first-child, .priceTableHolder td:first-child{ width: 38%;}
.priceTableHolder th:last-child, .priceTableHolder td:last-child{ width: 37%;}
.pricingBottom{ padding: 100px 0;}
.pricingBottom h3{ font-weight: 600; font-size: var(--fontsize20); }
.pricingBottom h3 span{ color: var(--basecolor); }
.pricingBottom h3 span span{ font-weight: 800;}
.mobileTableDetails{ display: none;}


@media only screen and (max-width: 1400px) {
  :root{ 
    --fontsize59:2.70em;
    --fontsize38:2.0em;
    --fontsize30:1.75em;
  }
  .featuredRightPadding{ padding: 85px 0 70px 30px; }
  .pointleft{ width: 100%;}
  .emplyeeSection{ padding: 65px 0 0 0;}
  .teamworkSection{ padding: 65px 0;}
  .sliderNavHolder h2{ margin-bottom: 10px;}
  .managementSection_inner{ padding-top: 0;}
  .customerSection{ padding: 65px 0;}
  .informationSection{ padding: 65px 0 45px 0;}
  .runSection{ padding: 65px 0;}
  .commingSoonSection{ padding: 65px 0;}
  .mb-7{ margin-bottom: 35px;}
  .imageboxImage{ margin-bottom: 15px;}
  hr{ margin-top: 30px;}
  .customerDevelopmentSection{ margin: 0;}
  .abtSection{ padding: 65px 0;}
  .priceTableHolder th, .priceTableHolder td {  padding: 18px 15px;}
  .priceTableHolder th:last-child, .priceTableHolder td:last-child{ width: 40%;}
}
@media only screen and (max-width: 1199px) {
  .featuredLeft:before{ border-radius: 0; left: -100%;  right: inherit;}
  .featuredLeftPadding{ padding: 60px 0;}
  .featuredRight:after{border-radius: 0; right: -100%;  left: inherit; }
  .featuredRightPadding{padding: 60px 0; }
  .managementSection .cardleft{ padding: 0;}
  .managementSection .cardright{ padding: 0;}
  .customerInner{ padding: 40px 45px;}
  .bannerImage{ margin: 0 0 45px 0;}
  .bannerSection{ padding-bottom: 45px;}
  .bannerSection h1 br{ display: none;}
  .sliderImage img{ margin: 0 auto;}
  .sliderNavHolder h2{ text-align: center;}
  .sliderNavHolder img{ margin: 5px !important;}
  .pricePagebannerContent{ margin-top: -220px;}
  .pricPageBanner{ padding-bottom: 300px;}

  
.priceTableHolder table { border: 0;}
.priceTableHolder table thead {  border: none;  clip: rect(0 0 0 0);  height: 1px;  margin: -1px;  overflow: hidden;  padding: 0;  position: absolute;  width: 1px;}
.priceTableHolder table tr {  border-bottom:none;  display: block;  margin-bottom: 0;  border-bottom: 5px solid #828edc;}
.priceTableHolder table td {  border-bottom: 1px solid #ddd;  display: block;  padding-left: 120px !important;  position: relative;}
.priceTableHolder table td::before {  content: attr(data-label);  float: left;  font-weight: bold;  text-transform: uppercase;  font-size: 14px !important;  color: var(--basefontcolor);  position: absolute;   left: 15px;  top: 14px;}
.priceTableHolder table td:last-child {  border-bottom: 0;}

.priceTableHolder th:first-child, .priceTableHolder td:first-child{ width: 100% !important; text-align: left;}
.priceTableHolder th:last-child, .priceTableHolder td:last-child{ width: 100% !important; text-align: left;}
.priceTableHolder td:not(:first-child){ text-align: left;}

.priceTableHolder th, .priceTableHolder td {
  border: 1px solid #fbfbfb;
}

.priceTableHolder th, .priceTableHolder td{ padding-top: 12px; padding-bottom: 12px;}
.priceTableHolder td:not(:first-child){ text-align: right;}
.priceTableHolder th:first-child, .priceTableHolder td:first-child{ text-align: right;}

.tableDetailsHolder small{ font-size: var(--fontsize15); font-weight: 600; padding: 10px;  background: var(--basecolor);  border-radius: 6px; color: #fff;  display: inline-block;
  margin-top: 0; }
.tableDetailsHolder span{ font-weight: 700;   font-size: var(--fontsize32);   color: var(--basefontcolor);  line-height: 1.6;}
.flextab{ display: flex; align-items: center; margin-bottom: 10px;}
.mobileTableDetails{ padding: 15px 0; display: block;}
.tableListitems { padding-top: 10px;}
.pricePagebannerContent{ margin-top: 45px;}
.pricPageBanner{ padding-bottom: 65px;}
.tableDetailsHolder span{ padding-left: 10px;}
.listContent span{ padding-left: 0;}

  
}
@media only screen and (max-width: 992px) {
  .managemtImage{ position: relative; left: 0; width: 100%; top: inherit; margin: 0 0 45px 0;} 
  .managementSection h2{ margin-bottom: 25px;}
  .iconBoxHolder{ gap: 15px;}
  .managementSection .icon_card{ width: calc(50% - 15px);}  
  .managementSection_inner{ border-bottom-left-radius: 50% 10%;  border-bottom-right-radius: 50% 10%; padding-bottom: 45px;}
  .runImage{ margin-bottom: 35px;}
  .imageInfoSection_inner{ border-top-left-radius: 50% 10%;    border-top-right-radius: 50% 10%; padding-top: 85px;}
  .imageBox{ text-align: center;}
  .commingImage{ margin-bottom: 35px;}
  .commingImage2{ margin: 0 auto;}
  .commingSoonSection ul li:after{ top: -3px;}
  .customerImage{ padding: 0 0 30px 0;}
  .footerContent{ padding: 0 0 30px 0; text-align: center;}
  .footerImage{ margin: 0 auto;}
  .marginLeft{ margin-left: 0;}
  .navHolder .nav-link{ padding:15px 25px !important;}
  .navbar-collapse{ background: #3793fb; }
  .navHolder .nav-link{ color: #fff;}
  .headerLogin{ width: 50%;}
  .headergetStarted{ width: 50%;}
  .headerLogin a{ background: #000;  padding: 15px; display: block; margin: 0; text-align: center; color: #fff;}
  .headergetStarted a{ padding: 15px; text-align: center; border-radius: 0;}
  .marginLeft{ padding: 0;}
  .navbar-toggler:focus{ box-shadow: none;}
  .navHolder a.nav-link.active {  color: var(--basecolor);  background: #2c75c9;  color: #fff;}
  .navHolder .nav-item{ border-bottom: 1px solid #6da8eb;}

}
@media only screen and (max-width: 767px) {
  .topLinkText{ display: none;}
  .bannerSection{ text-align: center;}
  h1{ font-size: var(--fontsize30);}
  a.btnGetStarted{ margin: 0;}
  .featuredHolder h2{ font-size: var(--fontsize30);}
  .featuredHolder h2 span{ font-size: 22px;}
  .pointsHolder{ flex-direction: column;}
  .paddingContainer{ padding: 0 0 30px 0;}
  .emplyeeSection ul{ padding: 0;}
  .sideMobileImage{ display: none;}
  .emplyeeBelowImage{ margin-top: 15px;}
  .sliderImage{ padding: 0; margin-bottom: 30px;}
  .sliderNavHolder .slick-slide{ width: 100% !important;}
  .cardright{ padding: 0;}
  .teamworkSection{ padding-bottom: 0;}
  .customerBoxHolder{ flex-wrap: wrap; flex-direction: column;}
  .customerBox{ width: 100%; margin: 0 0 45px 0;}
  .customerBox:last-child{ margin: 0;}
  .runSection .accordion-item h2 button span{ width: 90%;}
  .imageInfoSection_inner{ border-radius: 0; padding-top: 65px;}
  .imageboxImage{ margin-bottom: 25px;}
  .commingImage2{ margin-bottom: 25px;}
  .customerInner{padding: 40px 15px}
  .managementSection_inner{ padding: 0 30px; text-align: center; border-radius: 0;}
  .managementSection .icon_card{ width: 100%;}
  .iconBoxHolder{ gap: 0;}
  .cardleft, .cardright{ padding: 0; }
  .icon_card{ text-align: center;}
  .sliderNavHolder img{ margin: 0 auto !important;}
  .infoIconbox{ padding: 25px;}
  .imageInfoSection_inner{ padding:30px 15px;}
  .aboutBottomImage{ max-width: 220px;}
  .appButonHolder ul li{ margin: 0 0 10px 0;}
  .fooerContent{ flex-wrap: wrap; flex-direction: column;  gap: 30px;}
  .emplyeePartionImage{ display: none;}
  .featuredLeftPadding, .featuredRightPadding{ padding: 45px 0 30px 0;}
  .emplyeeSection{ padding: 45px 0 0 0;}
  .teamworkSection{ padding: 45px 0 0;}
  .customerSection{ padding: 45px 0;}
  .informationSection{ padding: 45px 0 30px 0; }
  .runSection{ padding: 45px 0;}
  .commingSoonSection{ padding: 15px 0;}
  .mb-7{ margin-bottom: 25px;}
  .abtSection{ padding: 45px 0;}
  span.tplink{ display: none;}
  .emailText{ display: none;}
  .emailicon{ display: block;}
  .headerTopContent{ justify-content: center;}
  .headerTopContent ul li:first-child svg{ font-size: inherit;}
  .headerTopContent ul li a{ font-size: var(--fontsize28);}
  .headerTopContent ul li{ margin: 0 15px 0 0;}
  .headerTopContent ul li:first-child::after{ display: none;}
  .navbar-collapse{ margin-top: 10px;}
  .pricingBottom{ padding: 50px 0;}
  .pricPageBanner{ padding-top: 30px;}
  .listContent{ padding-left: 15px;}
  .tableDetailsHolder span{ font-size: 20px;}
  .tablelist{ padding: 10px;}
  .listContent{ font-size: 13px;}
  .listContent{ padding-left: 10px;}
  .tablelist{ margin: 0 0 5px 0;}
  

  
}